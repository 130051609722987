import React, { useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import gsap from "gsap"
import ScrollMagic from "scrollmagic"
import scrollTo from "gatsby-plugin-smoothscroll"

// import ModuleCard from "./ModuleCard"

import WrapperDiv from "../StyledElements/WrapperDiv"
import HeadlineTwo from "../StyledElements/HeadlineTwo"
import BodyOne from "../StyledElements/BodyOne"
import { colors, buttonOne } from "../../Utilities"
import HeadlineThree from "../StyledElements/HeadlineThree"

const ScrollButtonOne = styled.button`
  ${buttonOne};
`

const EssentialModsSection = styled.section`
  position: relative;
  background-color: ${colors.veryLightBlue};
  padding: 2rem 0;

  @media (min-width: 1025px) {
    padding: 10rem 0 10rem;
  }

  .essentialWrapper {
    @media (min-width: 1025px) {
      max-width: 130rem;
    }
    @media (min-width: 1300px) {
      max-width: 140rem;
    }
  }

  .titleSection {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      width: 100%;
      max-width: 50rem;
      margin: auto;
    }

    @media (min-width: 1025px) {
      max-width: 65rem;
    }

    @media (min-width: 1200px) {
      max-width: 75rem;
    }

    h2 {
      margin-top: 0;
    }
  }

  .modsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 1025px) {
      padding-top: 3rem;
    }
  }

  .requestButton {
    position: absolute;
    bottom: -2.5rem;
    right: 0;
    left: 0;
    text-align: center;
  }
`

const ModuleCardSection = styled.a`
  display: block;
  width: calc(100% - 2rem);
  margin: 2rem;
  padding: 2rem;
  border-radius: 0.75rem;
  background-color: ${colors.white};
  transition: all 0.5s ease;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
  text-align: center;

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
  }

  @media (min-width: 1125px) {
    width: calc(25% - 1.5rem);
    margin: 0 0.75rem;
    padding: 2rem 1rem;
  }

  &:hover {
    background-color: #f6f6f6;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .icon {
    width: 100%;
    max-width: 15rem;
    margin: 0 auto 2rem;

    @media (min-width: 768px) {
      max-width: 11rem;
    }
  }

  .title {
  }
`

const EssentialMods = ({ data }) => {
  const title = data.acf._sol_emod_main_title
  const subTitle = data.acf._sol_emod_sub_title
  const modules = data.acf._sol_emod_essential_modules

  useEffect(() => {
    const cards = document.querySelectorAll(".modsCard")
    const controller = new ScrollMagic.Controller()
    const timeLine = gsap.timeline()
    gsap.set(cards, { opacity: 0, y: 400 })

    timeLine.to(cards, {
      opacity: 1,
      y: 0,
      duration: 0.4,
      ease: "power2.out",
      stagger: {
        each: 0.2,
      },
    })

    timeLine.pause()
    new ScrollMagic.Scene({
      duration: 0,
      offset: -150,
      triggerElement: "#modCardTrigger",
    })
      .on("enter", function(e) {
        timeLine.play()
      })
      .addTo(controller)
  }, [])

  return (
    <EssentialModsSection id="modCardTrigger">
      <WrapperDiv modifier="essentialWrapper">
        <div className="titleSection">
          <HeadlineTwo type="h1">{title}</HeadlineTwo>
          <BodyOne>
            <p>{subTitle}</p>
          </BodyOne>
        </div>
        {modules.length > 0 && (
          <div className="modsWrapper">
            {modules.map((mod, index) => {
              return (
                <ModuleCardSection
                  className="modsCard"
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={mod.url}
                >
                  <div className="icon">
                    <Img
                      fluid={mod.icon.localFile.childImageSharp.fluid}
                      alt={mod.icon.alt_text}
                    />
                  </div>
                  <div className="title">
                    <HeadlineThree type="h2">{mod.title}</HeadlineThree>
                  </div>
                  <BodyOne>
                    <p>{mod.description}</p>
                  </BodyOne>
                </ModuleCardSection>
              )
            })}
          </div>
        )}
        <div className="requestButton">
          <ScrollButtonOne onClick={() => scrollTo(`#mailchimpFormSection`)}>
            Request a Demo
          </ScrollButtonOne>
        </div>
      </WrapperDiv>
    </EssentialModsSection>
  )
}

export default EssentialMods
