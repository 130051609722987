import React from "react"
import styled from "styled-components"

import { bodyCopyOne, colors } from "../../Utilities"

const ExpertPointStyled = styled.li`
  ${bodyCopyOne};
  position: relative;
  margin-bottom: 0.5rem;
  padding-left: 2rem;

  &::before {
    display: block;
    position: absolute;
    top: 0.75rem;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${colors.iceBlue};
    content: "";
  }
`

const ExpertPoint = ({ data }) => {
  return (
    <>
      <ExpertPointStyled>{data.point}</ExpertPointStyled>
    </>
  )
}

export default ExpertPoint
