import React, { useState } from "react"
import styled from "styled-components"

import HeadlineOne from "../StyledElements/HeadlineOne"
import ButtonOne from "../StyledElements/ButtonOne"
import WrapperDivSmall from "../StyledElements/WrapperDivSmall"

import ContactFormSvg from "../../elements/ContactFormSvg"
import SolverLogoSvg from "../../elements/SolverLogoSvg"

import InputText from "../allSite/formParts/InputText"
import InputTextArea from "../allSite/formParts/InputTextArea"
import { submitTheForm } from "../allSite/formParts/FormUtilities/formFunctions"

// models //
import SubmitDisplay from "../allSite/formParts/FormUtilities/SubmitDisplay"
import ErrorDisplay from "../allSite/formParts/FormUtilities/ErrorDisplay"
import SuccessLetsChat from "../allSite/formParts/FormUtilities/SuccessLetsChat"

const ContactFormSection = styled.section`
  margin: 3rem auto;

  @media (min-width: 1025px) {
    margin: 4rem auto;
  }

  .formWarpper {
    margin: auto;
    border-radius: 2.5rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px rgba(0, 0, 0, 0.24);
    background-color: #ffffff;

    @media (min-width: 1025px) {
      max-width: 100rem;
      padding: 4rem;
    }
  }

  .formIcons {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(35%);
      padding: 2rem;
    }

    &__logo {
      margin-bottom: 1rem;
    }

    &__graphic {
      padding: 3rem;
    }
  }

  .formContainer {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(65%);
    }

    .form-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      .form-submit {
        width: 100%;
        margin-top: 2.5rem;
        text-align: left;
      }
    }
  }
`

const ContactForm = () => {
  const [formData, updateFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyEmail: "",
    phoneNumber: "",
    province: "",
    current: "",
    comments: "",
  })

  const [formStatus, updateFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
    captachError: false,
  })

  const handleOnChange = e => {
    updateFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleOnSubmit = (e, formId, formData, updateFormStatus) => {
    e.preventDefault()
    updateFormStatus({
      ...formStatus,
      submitting: true,
      captachError: false,
    })

    submitTheForm(e, formId, formData, updateFormStatus)
  }
  return (
    <ContactFormSection id="mailchimpFormSection">
      <WrapperDivSmall modifier="formWarpper">
        <div className="formIcons">
          <div className="formIcons__logo">
            <SolverLogoSvg />
          </div>
          <div className="formIcons__graphic">
            <ContactFormSvg />
          </div>
        </div>
        <div className="formContainer">
          <HeadlineOne type="h2">
            Take the first step toward <br />
            helping your business grow.
          </HeadlineOne>
          <form
            className="form-main"
            onSubmit={e => {
              handleOnSubmit(e, 130, formData, updateFormStatus)
            }}
          >
            <div className="form-wrapper">
              <InputText
                name="firstName"
                type="text"
                placeholder=""
                label="Your First Name"
                value={formData.firstName}
                onChange={handleOnChange}
                errors={formStatus.errors}
                required={true}
                width="half"
                formSide="left"
                textColor=""
              />
              <InputText
                name="lastName"
                type="text"
                placeholder=""
                label="Your Last Name"
                value={formData.lastName}
                onChange={handleOnChange}
                errors={formStatus.errors}
                required={true}
                width="half"
                formSide="right"
                textColor=""
              />
              <InputText
                name="companyName"
                type="text"
                placeholder=""
                label="Company"
                value={formData.companyName}
                onChange={handleOnChange}
                errors={formStatus.errors}
                required={true}
                width="full"
                formSide=""
                textColor=""
              />
              <InputText
                name="companyEmail"
                type="email"
                placeholder=""
                label="Company Email Address"
                value={formData.companyEmail}
                onChange={handleOnChange}
                errors={formStatus.errors}
                required={true}
                width="full"
                formSide="full"
                textColor=""
              />
              <InputText
                name="phoneNumber"
                type="text"
                placeholder=""
                label="Phone Number"
                value={formData.phoneNumber}
                onChange={handleOnChange}
                errors={formStatus.errors}
                required={false}
                width="half"
                formSide="left"
                textColor=""
              />
              <InputText
                name="province"
                type="text"
                placeholder=""
                label="Province"
                value={formData.province}
                onChange={handleOnChange}
                errors={formStatus.errors}
                required={true}
                width="half"
                formSide="right"
                textColor=""
              />
              <InputText
                name="current"
                type="text"
                placeholder=""
                label="Which ERP are you currently using?"
                value={formData.current}
                onChange={handleOnChange}
                errors={formStatus.errors}
                required={true}
                width="full"
                formSide=""
                textColor=""
              />
              <InputTextArea
                name="comments"
                placeholder=""
                label="Comments"
                value={formData.comments}
                onChange={handleOnChange}
                errors={formStatus.errors}
                required={false}
                rows={5}
                textColor=""
              />
              <div className="form-submit">
                <ButtonOne>submit</ButtonOne>
              </div>
            </div>
          </form>
        </div>
      </WrapperDivSmall>
      {formStatus.submitting && <SubmitDisplay />}
      {formStatus.errorWarnDisplay && (
        <ErrorDisplay formStatus={formStatus} stateSetter={updateFormStatus} />
      )}
      {formStatus.success && (
        <SuccessLetsChat
          updateFormData={updateFormData}
          updateFormStatus={updateFormStatus}
        />
      )}
    </ContactFormSection>
  )
}

export default ContactForm
