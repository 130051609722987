import React, { useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import gsap from "gsap"
import ScrollMagic from "scrollmagic"

import LaptopPng from "../../elements/LaptopPng"

const ExpertiseLaptopStyled = styled.div`
  position: relative;
  width: 100%;
  margin: 2.5rem auto;

  @media (min-width: 768px) {
    max-width: 55rem;
    margin: 5rem auto 10rem;
    margin-top: 25rem;
  }

  .solverLaptop {
    position: relative;
    z-index: 100;
  }

  .mainScreen {
    position: absolute;
    top: 1.5rem;
    left: 16.5%;
    width: 69%;
    z-index: 10;

    @media (min-width: 768px) {
      width: 69%;
      top: 1.75rem;
      left: 16.5%;
    }
  }

  .secondScreen {
    display: none;
    position: absolute;
    top: 1.5rem;
    left: 16.5%;
    width: 69%;
    z-index: 5;

    @media (min-width: 768px) {
      display: block;
      top: 3.5rem;
      left: -40%;
      width: 60%;
    }
  }

  .thirdScreen {
    display: none;
    position: absolute;
    top: 1.5rem;
    left: 16.5%;
    width: 69%;
    z-index: 5;

    @media (min-width: 768px) {
      display: block;
      position: absolute;
      top: 3.5rem;
      right: -40%;
      left: auto;
      width: 60%;
    }
  }

  .fourthScreen {
    display: none;
    position: absolute;
    top: 1.5rem;
    left: 16.5%;
    width: 69%;
    z-index: 1;

    @media (min-width: 768px) {
      display: block;
      top: -15rem;
      left: -30%;
      width: 60%;
    }
  }

  .fifthScreen {
    display: none;
    position: absolute;
    top: 1.5rem;
    left: 16.5%;
    width: 69%;
    z-index: 1;

    @media (min-width: 768px) {
      display: block;
      position: absolute;
      top: -15rem;
      right: -30%;
      left: auto;
      width: 60%;
    }
  }
`

const ExpertiseLaptop = ({ screens }) => {
  useEffect(() => {
    const screenTwo = document.querySelector(".secondScreen")
    const screenThree = document.querySelector(".thirdScreen")
    const screenFour = document.querySelector(".fourthScreen")
    const screenFive = document.querySelector(".fifthScreen")
    const controller = new ScrollMagic.Controller()
    const timeLine = gsap.timeline()

    gsap.set(screenTwo, { autoAlpha: 0, x: 250 })
    gsap.set(screenThree, { autoAlpha: 0, x: -250 })
    gsap.set(screenFour, { autoAlpha: 0, y: 200 })
    gsap.set(screenFive, { autoAlpha: 0, y: 200 })

    timeLine
      .to(screenTwo, { autoAlpha: 1, x: 0, duration: 0.75 })
      .to(screenThree, { autoAlpha: 1, x: 0, duration: 0.75 }, "<")
      .add("stageTwo")
      .to(screenFour, { autoAlpha: 1, y: 0, duration: 0.5 }, "stageTwo")
      .to(screenFive, { autoAlpha: 1, y: 0, duration: 0.5 }, "stageTwo")

    timeLine.pause()
    new ScrollMagic.Scene({
      duration: 0,
      offset: -150,
      triggerElement: "#lapTopTigger",
    })
      .on("enter", function(e) {
        timeLine.play()
      })
      .addTo(controller)
  }, [])

  return (
    <ExpertiseLaptopStyled id="lapTopTigger">
      <LaptopPng />
      <div className="mainScreen">
        <Img
          fluid={screens[0].localFile.childImageSharp.fluid}
          alt={screens[0].alt_text}
        />
      </div>
      <div className="secondScreen">
        <Img
          fluid={screens[1].localFile.childImageSharp.fluid}
          alt={screens[1].alt_text}
        />
      </div>
      <div className="thirdScreen">
        <Img
          fluid={screens[2].localFile.childImageSharp.fluid}
          alt={screens[2].alt_text}
        />
      </div>
      <div className="fourthScreen">
        <Img
          fluid={screens[3].localFile.childImageSharp.fluid}
          alt={screens[3].alt_text}
        />
      </div>
      <div className="fifthScreen">
        <Img
          fluid={screens[4].localFile.childImageSharp.fluid}
          alt={screens[4].alt_text}
        />
      </div>
    </ExpertiseLaptopStyled>
  )
}

export default ExpertiseLaptop
