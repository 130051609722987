import React, { useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import gsap from "gsap"

import WrapperDiv from "../StyledElements/WrapperDiv"
import HeadlineTwo from "../StyledElements/HeadlineTwo"
import HeadlineThree from "../StyledElements/HeadlineThree"
import AnchorOne from "../StyledElements/AnchorOne"
import AnchorTwo from "../StyledElements/AnchorTwo"
import LinkOne from "../StyledElements/LinkOne"
import LinkTwo from "../StyledElements/LinkTwo"
import { bodyCopyOne, buttonOne, buttonTwo } from "../../Utilities"

import LaptopPng from "../../elements/LaptopPng"

const ScrollButtonOne = styled.button`
  ${buttonOne};
`

const ScrollButtonTwo = styled.button`
  ${buttonTwo};
`

const IntroSection = styled.section`
  position: relative;
  width: 100%;
  margin-top: 0;
  overflow: hidden;

  @media (min-width: 768px) {
    margin-top: 5rem;
    padding-bottom: 5rem;
  }

  @media (min-width: 1025px) {
    margin-top: 10rem;
    margin-bottom: 0;
  }

  .intro-section-wrapper {
    position: relative;

    @media (min-width: 1025px) {
      padding-top: 0;
      padding-bottom: 10rem;
      padding-left: 8rem;
    }

    @media (min-width: 1200px) {
      padding-top: 0;
      padding-bottom: 12.5rem;
      padding-left: 15rem;
    }
  }

  .introContent {
    width: 100%;
    padding-bottom: 5rem;

    @media (min-width: 768px) {
      width: 65%;
      margin-right: 35%;
      padding-bottom: 0;
    }
    @media (min-width: 800px) {
      width: 60%;
      margin-right: 40%;
    }
    @media (min-width: 1025px) {
      width: 55%;
      margin-right: 45%;
    }

    h2 {
      margin-top: 0;
    }

    .btnWrapper {
      text-align: center;

      @media (min-width: 768px) {
        text-align: left;
      }

      a:first-of-type {
        margin-top: 2rem;
        margin-bottom: 5rem;

        @media (min-width: 768px) {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 2rem;
        }
      }
    }
  }

  .introGraphicWrapper {
    position: relative;
    width: 100%;
    margin-top: 5rem;
    opacity: 0;
    visibility: hidden;

    @media (min-width: 768px) {
      position: absolute;
      top: 1rem;
      right: -10%;
      width: 45%;
      margin-top: 0;
    }

    @media (min-width: 800px) {
      top: 0;
      right: -7.5%;
      width: 45%;
    }

    @media (min-width: 1025px) {
      right: -7.5%;
      width: 38vw;
      min-width: 50rem;
      max-width: 58rem;
      height: 100%;
    }

    @media (min-width: 1600px) {
      right: 0;
    }
  }

  .introGraphic {
    position: relative;
    width: 100%;
    z-index: 5;
  }

  .introScreen {
    position: absolute;
    top: 1.7rem;
    left: 15.862%;
    width: 70.5%;
    z-index: 1;
  }
`

const ContentContainer = styled.div`
  p {
    ${bodyCopyOne};
  }
`

const AngledBackground = styled.div`
  position: absolute;
  bottom: -65rem;
  right: -170%;
  width: 130%;
  height: 100%;
  transform-origin: left bottom;
  transform: rotate(-30deg);
  background-color: #e5f3f4;
  z-index: -1;

  @media (min-width: 768px) {
    width: 100%;
    bottom: -40rem;
    right: -50%;
  }

  @media (min-width: 1025px) {
    bottom: -50rem;
    right: -50%;
  }

  @media (min-width: 1200px) {
    bottom: -87.5rem;
    right: -50%;
    height: 75rem;
  }

  @media (min-width: 1600px) {
    bottom: -95rem;
  }
`

const Intro = ({ data }) => {
  const title = data.acf._sol_tsec_title
  const subTitle = data.acf._sol_tsec_sub_title
  const content = data.acf._sol_tsec_content
  const screen = data.acf._sol_tsec_graphic_screen

  const screenFluid = screen.localFile
    ? screen.localFile.childImageSharp.fluid
    : false
  const screenAlt = screen.alt_text ? screen.alt_text : ""

  const buttonRequired = data.acf._sol_tsec_button_req
  const buttonText = data.acf._sol_tsec_button_text
  const buttonType = data.acf._sol_tsec_button_link
  const buttonSlug = data.acf._sol_tsec_interior_page_slug
  const buttonUrl = data.acf._sol_tsec_exterior_link

  const button2Required = data.acf._sol_tsec_button2_req
  const button2Text = data.acf._sol_tsec_button2_text
  const button2Type = data.acf._sol_tsec_button2_link
  const button2Slug = data.acf._sol_tsec_interior2_page_slug
  const button2Url = data.acf._sol_tsec_exterior2_link

  useEffect(() => {
    const lapTop = document.querySelector(".introGraphicWrapper")
    const timeline = gsap.timeline({ delay: 0.7 })
    gsap.set(lapTop, { autoAlpha: 0, x: 200 })
    timeline.to(lapTop, { autoAlpha: 1, x: 0, duration: 2 })
  }, [])

  return (
    <IntroSection>
      <WrapperDiv modifier="intro-section-wrapper">
        <div className="introContent">
          <HeadlineTwo type="h1">{title}</HeadlineTwo>
          <HeadlineThree type="h3">{subTitle}</HeadlineThree>
          <ContentContainer dangerouslySetInnerHTML={{ __html: content }} />
          <div className="btnWrapper">
            {buttonRequired === "yes" ? (
              <div className="btnWrapper">
                {buttonType === "exte" ? (
                  <AnchorOne url={buttonUrl}>{buttonText}</AnchorOne>
                ) : buttonType === "inter" ? (
                  <LinkOne url={buttonSlug}>{buttonText}</LinkOne>
                ) : buttonType === "page" ? (
                  <ScrollButtonOne
                    onClick={() => scrollTo(`#mailchimpFormSection`)}
                  >
                    {buttonText}
                  </ScrollButtonOne>
                ) : (
                  ""
                )}
                {button2Required === "yes" ? (
                  button2Type === "exte" ? (
                    <AnchorTwo url={button2Url}>{button2Text}</AnchorTwo>
                  ) : button2Type === "inter" ? (
                    <LinkTwo url={button2Slug}>{button2Text}</LinkTwo>
                  ) : button2Type === "page" ? (
                    <ScrollButtonTwo
                      onClick={() => scrollTo(`#mailchimpFormSection`)}
                    >
                      {button2Text}
                    </ScrollButtonTwo>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {screenFluid && (
          <div className="introGraphicWrapper">
            <div className="introGraphic">
              <LaptopPng />
            </div>
            <div className="introScreen">
              <Img fluid={screenFluid} alt={screenAlt} />
            </div>
          </div>
        )}
      </WrapperDiv>
      <AngledBackground />
    </IntroSection>
  )
}

export default Intro
