import React from "react"
import styled from "styled-components"

import WrapperDivSmall from "../StyledElements/WrapperDivSmall"
import HeadlineTwo from "../StyledElements/HeadlineTwo"
// import AngledBackground from "../StyledElements/AngledBackground"
import { bodyCopyOne } from "../../Utilities"

import CompanyIconSvg from "../../elements/CompanyIconSvg"

const CompanyStyled = styled.section`
  position: relative;
  padding: 2.5rem 0 0;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 7rem 0;
  }
  @media (min-width: 1025px) {
    padding: 7.5rem 0;
  }

  .companyWrapper {
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }

  .companyText {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(60% - 2rem);
      margin-left: 2rem;
    }
    .content {
      ${bodyCopyOne};
    }
  }

  .companyIcon {
    align-self: center;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(40% - 4rem);
      margin: 0 2rem;
      padding: 2rem;
    }
  }
`

const AngledBackground = styled.div`
  position: absolute;
  bottom: -75rem;
  left: -180%;
  width: 175%;
  height: 100%;
  transform-origin: right bottom;
  transform: rotate(30deg);
  background-color: #e5f3f4;
  z-index: -1;

  @media (min-width: 500px) {
    bottom: -85rem;
    left: -100%;
    width: 150%;
  }

  @media (min-width: 768px) {
    width: 100%;
    bottom: -60rem;
    left: -50%;
  }

  @media (min-width: 1025px) {
    bottom: -50rem;
    left: -50%;
  }

  @media (min-width: 1200px) {
    bottom: -87.5rem;
    left: -50%;
    height: 75rem;
  }

  @media (min-width: 1600px) {
    bottom: -95rem;
  }
`

const Company = ({ data }) => {
  const title = data.acf._sol_com_title
  const content = data.acf._sol_com_content
  return (
    <CompanyStyled>
      <WrapperDivSmall modifier="companyWrapper">
        <div className="companyText">
          <div>
            <HeadlineTwo type="h1">{title}</HeadlineTwo>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        <div className="companyIcon">
          <CompanyIconSvg />
        </div>
      </WrapperDivSmall>
      <AngledBackground />
    </CompanyStyled>
  )
}

export default Company
