import React from "react"
import styled from "styled-components"

const IconStyled = styled.div`
  svg {
    width: 100%;
    max-width: 100%;
  }

  .cls-1,
  .cls-17 {
    fill: #a8c4e5;
  }

  .cls-1,
  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-2,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-3,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9 {
    fill-rule: evenodd;
  }

  .cls-1 {
    opacity: 0.4;
  }

  .cls-2 {
    fill: #dbefff;
  }

  .cls-3 {
    fill: #b0daff;
  }

  .cls-4,
  .cls-9 {
    fill: #2c4482;
  }

  .cls-5,
  .cls-6 {
    fill: #5980ab;
  }

  .cls-7 {
    fill: #fff;
  }

  .cls-8 {
    fill: #363656;
  }

  .cls-10 {
    fill: #45bfab;
  }

  .cls-11 {
    fill: #50dec6;
  }

  .cls-12 {
    fill: #dea749;
  }

  .cls-13 {
    fill: #ebd265;
  }

  .cls-14 {
    fill: #dde4ed;
  }

  .cls-15 {
    fill: #4367c7;
  }

  .cls-16 {
    fill: #5986ff;
  }

  .cls-18 {
    fill: #9ecc45;
  }

  .cls-19 {
    fill: #ca9942;
  }

  .cls-20 {
    fill: #ffd6c4;
  }

  .cls-21 {
    fill: #efefef;
  }

  .cls-22 {
    fill: #1c6bb5;
  }

  .cls-23 {
    fill: #252525;
  }

  .cls-24 {
    fill: #c59178;
  }
`

const ExpertiseIconSvg = () => {
  return (
    <IconStyled>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="356.947"
        height="373.909"
        viewBox="0 0 356.947 373.909"
      >
        <g transform="translate(-51.8 -24.2)">
          <path
            d="M240.493 270.383c-7.066 4.122-15.457 6.036-25.32 6.036s-18.4-2.061-25.32-6.036c-7.066-4.122-10.6-8.98-10.452-14.574 0-5.741 3.533-10.6 10.452-14.574 7.066-4.122 15.457-6.036 25.32-6.036s18.4 2.061 25.32 6.036q10.6 6.183 10.6 14.574c-.001 5.591-3.534 10.599-10.6 14.574z"
            className="cls-1"
            data-name="Path 3438"
            transform="translate(60.236 99.61)"
          ></path>
          <path
            d="M278.393 257.083c-7.066 4.122-15.457 6.036-25.32 6.036s-18.4-2.061-25.32-6.036c-7.066-4.122-10.6-8.98-10.452-14.574 0-5.741 3.533-10.6 10.452-14.574 7.066-4.122 15.457-6.036 25.32-6.036s18.4 2.061 25.32 6.036q10.6 6.183 10.6 14.574-.222 8.391-10.6 14.574z"
            className="cls-1"
            data-name="Path 3439"
            transform="translate(78.128 93.331)"
          ></path>
          <path
            d="M124.374 308.177l89.5-51.67L141.45 214.7 51.8 266.37z"
            className="cls-1"
            data-name="Path 3440"
            transform="translate(0 89.932)"
          ></path>
          <path
            d="M192.929 24.2l-29 149.858L60 233.677 89.147 83.819z"
            className="cls-2"
            data-name="Path 3441"
            transform="translate(3.871)"
          ></path>
          <path
            d="M193.929 26.4l-29 149.711L61 235.73 90.147 86.019z"
            className="cls-3"
            data-name="Path 3442"
            transform="translate(4.343 1.039)"
          ></path>
          <g data-name="Group 3212" transform="translate(59.801 77.247)">
            <path
              d="M63.072 343.559a1.867 1.867 0 01-.883-.147 5.773 5.773 0 01-4.858-6.772l43.132-271.452a5.893 5.893 0 1111.629 1.912L68.813 338.554a5.839 5.839 0 01-5.741 5.005z"
              className="cls-4"
              data-name="Path 3443"
              transform="translate(-57.235 -60.235)"
            ></path>
          </g>
          <g data-name="Group 3213" transform="translate(62.304 78.866)">
            <path
              d="M64.772 344.659a1.867 1.867 0 01-.883-.147 5.773 5.773 0 01-4.858-6.772l43.132-271.452a5.893 5.893 0 1111.629 1.912L70.66 339.654a5.877 5.877 0 01-5.888 5.005z"
              className="cls-5"
              data-name="Path 3444"
              transform="translate(-58.935 -61.335)"
            ></path>
          </g>
          <g data-name="Group 3214" transform="translate(131.197 36.176)">
            <path
              d="M111.572 315.806a1.867 1.867 0 01-.883-.147 5.773 5.773 0 01-4.858-6.772l43.279-271.6a5.893 5.893 0 0111.629 1.913L117.46 310.8a6.106 6.106 0 01-5.888 5.006z"
              className="cls-4"
              data-name="Path 3445"
              transform="translate(-105.735 -32.335)"
            ></path>
          </g>
          <g data-name="Group 3215" transform="translate(133.405 37.795)">
            <path
              d="M113.072 316.906a1.867 1.867 0 01-.883-.147 5.773 5.773 0 01-4.858-6.772l43.279-271.6a5.893 5.893 0 0111.629 1.913L118.96 311.9a6.106 6.106 0 01-5.888 5.006z"
              className="cls-5"
              data-name="Path 3446"
              transform="translate(-107.235 -33.435)"
            ></path>
          </g>
          <g data-name="Group 3216" transform="translate(104.936 108.103)">
            <path
              d="M107.922 367.669a5.951 5.951 0 01-5.888-5.594L87.9 87.385a5.9 5.9 0 0111.779-.585l14.132 274.543a5.914 5.914 0 01-5.594 6.183c-.147.143-.147.143-.295.143z"
              className="cls-4"
              data-name="Path 3447"
              transform="translate(-87.896 -81.196)"
            ></path>
          </g>
          <g data-name="Group 3217" transform="translate(108.469 109.575)">
            <path
              d="M110.322 368.522a5.951 5.951 0 01-5.888-5.594L90.3 88.385a5.9 5.9 0 1111.777-.589l14.132 274.543a5.914 5.914 0 01-5.594 6.183z"
              className="cls-5"
              data-name="Path 3448"
              transform="translate(-90.296 -82.196)"
            ></path>
          </g>
          <g data-name="Group 3218" transform="translate(175.743 66.443)">
            <path
              d="M156.022 339.369a5.951 5.951 0 01-5.888-5.594L136 59.085a5.9 5.9 0 1111.777-.589l14.132 274.543a5.914 5.914 0 01-5.594 6.183c-.145.147-.145.147-.293.147z"
              className="cls-4"
              data-name="Path 3449"
              transform="translate(-135.996 -52.896)"
            ></path>
          </g>
          <g data-name="Group 3219" transform="translate(178.982 68.062)">
            <path
              d="M158.222 340.322a5.951 5.951 0 01-5.888-5.594L138.2 60.185a5.9 5.9 0 0111.777-.589l14.132 274.543a5.914 5.914 0 01-5.594 6.183z"
              className="cls-5"
              data-name="Path 3450"
              transform="translate(-138.196 -53.996)"
            ></path>
          </g>
          <path
            d="M192.809 144.6L79.9 210.4l16.487 9.716 112.909-65.8z"
            className="cls-2"
            data-name="Path 3451"
            transform="translate(13.266 56.839)"
          ></path>
          <path
            d="M204.3 151.2L90.8 216.708v13.543l113.5-65.508z"
            className="cls-6"
            data-name="Path 3452"
            transform="translate(18.411 59.955)"
          ></path>
          <path
            d="M79.8 189.3l16.34 9.569v13.543l-16.34-9.569z"
            className="cls-3"
            data-name="Path 3453"
            transform="translate(13.218 77.941)"
          ></path>
          <path
            d="M184.782 25.7l6.33 182.1L87.33 267.71 81 85.614z"
            className="cls-7"
            data-name="Path 3454"
            transform="translate(13.785 .708)"
          ></path>
          <path
            d="M163.312 146.5L140.2 159.9l6.183 3.68 23.259-13.4z"
            className="cls-6"
            data-name="Path 3455"
            transform="translate(41.732 57.736)"
          ></path>
          <path
            d="M167.559 148.9L144.3 162.3v5.152l23.259-13.4z"
            className="cls-8"
            data-name="Path 3456"
            transform="translate(43.668 58.869)"
          ></path>
          <path
            d="M140 155.4l6.33 3.68v5.152l-6.33-3.68z"
            className="cls-9"
            data-name="Path 3457"
            transform="translate(41.638 61.937)"
          ></path>
          <path
            d="M91.277 192.875l4.858 2.8a1.765 1.765 0 002.061 0l14.574-8.391c.736-.442.736-.883 0-1.178l-4.858-2.8a1.765 1.765 0 00-2.061 0L91.277 191.7c-.736.292-.736.733 0 1.175z"
            className="cls-10"
            data-name="Path 3458"
            transform="translate(18.376 74.955)"
          ></path>
          <path
            d="M90.7 186.9l3.533 2.061v3.533L90.7 190.58z"
            className="cls-10"
            data-name="Path 3459"
            transform="translate(18.364 76.808)"
          ></path>
          <path
            d="M91.277 190.328l4.858 2.8a1.765 1.765 0 002.061 0l14.574-8.244c.736-.442.736-.736 0-1.178l-4.858-2.8a1.765 1.765 0 00-2.061 0l-14.574 8.244c-.736.294-.736.736 0 1.178z"
            className="cls-11"
            data-name="Path 3460"
            transform="translate(18.376 73.822)"
          ></path>
          <path
            d="M107.333 182.9l-3.533 2.061v3.827l3.533-2.061z"
            className="cls-12"
            data-name="Path 3461"
            transform="translate(24.548 74.92)"
          ></path>
          <path
            d="M107.588 185.525c.736.442.736.736 0 1.178l-5.888 3.386v-3.68l3.68-2.208z"
            className="cls-12"
            data-name="Path 3462"
            transform="translate(23.557 75.533)"
          ></path>
          <path
            d="M109.854 183.6c.736.442.736.736 0 1.178l-5.888 3.386-7.066-3.825 6.036-3.533a1.765 1.765 0 012.061 0z"
            className="cls-13"
            data-name="Path 3463"
            transform="translate(21.291 73.775)"
          ></path>
          <path
            d="M105.121 186.916l-2.061 1.325-7.36-4.416 2.208-1.325z"
            className="cls-7"
            data-name="Path 3464"
            transform="translate(20.724 74.731)"
          ></path>
          <path
            d="M102.661 185.5l-2.061 1.178v3.68l2.061-1.178z"
            className="cls-14"
            data-name="Path 3465"
            transform="translate(23.038 76.147)"
          ></path>
          <g data-name="Group 3220" transform="translate(113.775 157.068)">
            <path
              d="M94.194 162.509a3.465 3.465 0 01.294-1.178c.294-.442.442-.736.883-.883l10.746-6.183.294-.147v-13.984a1.958 1.958 0 01.442-1.178c.147-.442.442-.589.883-.883l7.213-4.122v-14.279a3.465 3.465 0 01.294-1.178 2.537 2.537 0 01.883-.883l5.152-2.944a.973.973 0 01.883-.147c.294.147.294.442.294.736a3.465 3.465 0 01-.294 1.178 2.5 2.5 0 01-.883.883l-3.975 2.355V133.8a1.958 1.958 0 01-.442 1.178c-.294.442-.442.736-.883.883l-7.213 4.122v13.985a3.465 3.465 0 01-.294 1.178c-.294.442-.442.736-.883.883l-11.188 6.48v33.269a1.958 1.958 0 01-.442 1.178c0 .147-.147.147-.147.294-.294.294-.442.589-.736.736a.973.973 0 01-.883.147c-.294-.147-.294-.442-.294-.736v-34.888z"
              className="cls-13"
              data-name="Path 3466"
              transform="translate(-93.9 -114.459)"
            ></path>
          </g>
          <path
            d="M127.534 120.068a60.15 60.15 0 00-15.751 20.168c-4.122 8.244-6.183 16.046-6.183 23.553l27.528-15.9-1.914 32.827q9.716-4.858 17.665-15.9a62.072 62.072 0 0010.6-23.406c1.766-8.244 1.325-15.162-1.178-20.462-2.65-5.447-6.772-8.244-12.66-8.244-5.594-.291-11.777 2.212-18.107 7.364z"
            className="cls-15"
            data-name="Path 3467"
            transform="translate(25.398 41.772)"
          ></path>
          <path
            d="M124.148 123.185a63.8 63.8 0 00-13.4 19.284c-3.386 7.508-5.152 14.574-5.152 21.345l27.528-15.9 21.64-32.091c-3.533-3.239-8.244-4.269-13.838-2.944-5.59 1.621-11.184 5.006-16.778 10.306z"
            className="cls-16"
            data-name="Path 3468"
            transform="translate(25.398 41.599)"
          ></path>
          <path
            d="M102.6 158.351c0 7.949 2.355 13.69 6.919 17.076s10.3 3.533 17.223.589l3.386-33.416z"
            className="cls-13"
            data-name="Path 3469"
            transform="translate(23.982 55.895)"
          ></path>
          <path
            d="M110.956 131.787v2.208L90.2 146.066v-2.208zm0-5.741v2.208L90.2 140.325v-2.208zm0-6.036v2.208L90.2 134.289v-2.208zM118.17 110v2.208L90.2 128.4v-2.208z"
            className="cls-17"
            data-name="Path 3470"
            transform="translate(18.128 40.505)"
          ></path>
          <g data-name="Group 3221" transform="translate(103.47 89.855)">
            <path
              d="M126.8 74.836l11.188-6.036 2.212 28.411-11.188 6.036z"
              className="cls-18"
              data-name="Path 3471"
              transform="translate(-68.064 -68.8)"
            ></path>
            <path
              d="M118.488 71.6l2.944 38.716-11.188 6.036-2.944-38.864z"
              className="cls-18"
              data-name="Path 3472"
              transform="translate(-77.27 -67.478)"
            ></path>
            <path
              d="M98.235 71.3l4.122 55.939-11.188 6.036L86.9 77.336z"
              className="cls-18"
              data-name="Path 3473"
              transform="translate(-86.9 -67.62)"
            ></path>
            <path
              d="M127.888 70.2L116.7 76.236l2.65 34.152 11.188-6.036z"
              className="cls-18"
              data-name="Path 3474"
              transform="translate(-72.832 -68.139)"
            ></path>
            <path
              d="M108.235 70.3L96.9 76.336l3.827 49.168 11.188-5.888z"
              className="cls-18"
              data-name="Path 3475"
              transform="translate(-82.179 -68.092)"
            ></path>
          </g>
          <path
            d="M131.3 67.909v-2.356l20.462-11.924v2.208zm20.609-19.873v2.208l-20.462 11.924v-2.209zm0-6.036v2.208l-20.462 11.924v-2.208z"
            className="cls-17"
            data-name="Path 3476"
            transform="translate(37.531 8.403)"
          ></path>
          <path
            d="M112.3 79.009V76.8l20.462-11.924v2.208zm20.462-19.873v2.208L112.3 73.268v-2.209zm0-6.036v2.208L112.3 67.232v-2.208z"
            className="cls-17"
            data-name="Path 3477"
            transform="translate(28.561 13.643)"
          ></path>
          <path
            d="M134.9 116.509V114.3l20.462-11.924v2.208zm20.609-19.873v2.208l-20.462 11.924v-2.208zm0-6.036v2.208l-20.462 11.924v-2.208z"
            className="cls-17"
            data-name="Path 3478"
            transform="translate(39.23 31.346)"
          ></path>
          <path
            d="M211.2 204.2v52.259l-66.1 38.274v-52.259z"
            className="cls-12"
            data-name="Path 3479"
            transform="translate(44.045 84.975)"
          ></path>
          <path
            d="M148.4 244.557l66.391-38.421-10.6-6.036-66.391 38.421z"
            className="cls-13"
            data-name="Path 3480"
            transform="translate(40.599 83.039)"
          ></path>
          <path
            d="M180.685 212.71l8.685-5.005-8.832-5.005-8.538 4.858z"
            className="cls-19"
            data-name="Path 3481"
            transform="translate(56.744 84.267)"
          ></path>
          <path
            d="M178.09 214.945v-5.3c-.147-2.65-.883-4.416-2.061-5.152q-1.987-2.208-5.741-.442c-.147 0-.147.147-.294.147l-9.274 5.594q-4.637 1.767-4.416 8.832v4.858a2.944 2.944 0 005.888 0v-4.858c0-1.619.294-2.8 1.178-3.386a.514.514 0 00.294-.147l8.685-5.152v5.152a2.915 2.915 0 005.005 2.061 4.38 4.38 0 00.736-2.207z"
            className="cls-19"
            data-name="Path 3482"
            transform="translate(49.329 84.534)"
          ></path>
          <path
            d="M151.685 229.51l8.685-5.005-8.685-5.005-8.685 5.005z"
            className="cls-19"
            data-name="Path 3483"
            transform="translate(43.054 92.198)"
          ></path>
          <path
            d="M138.1 225.9v52.406l10.6 6.036v-52.259z"
            className="cls-19"
            data-name="Path 3484"
            transform="translate(40.741 95.219)"
          ></path>
          <path
            d="M298.9 69.319a4.817 4.817 0 013.386-1.619c6.33.147 9.569 4.416 9.569 12.513-7.36 7.213-11.335 11.041-12.071 11.629-.589.294-2.8 1.914-6.772 4.858a44.132 44.132 0 01-6.919 4.416 29.042 29.042 0 00-5.741 4.122 62.036 62.036 0 01-5.741 4.416 9.555 9.555 0 01-5.594 1.325 8.389 8.389 0 01-5.447-1.325 44.129 44.129 0 00-9.421-4.269c-4.416-1.472-7.8-2.65-9.863-3.386a45.874 45.874 0 01-6.624-3.827 50.992 50.992 0 00-6.624-3.533c-3.386-1.178-5.888-2.208-7.508-2.8a18.628 18.628 0 01-6.624-4.416 22.915 22.915 0 016.772-10.01 36.379 36.379 0 013.975-3.091h11.188a1.272 1.272 0 00.883.442 64.51 64.51 0 0115.751 6.477 59.381 59.381 0 0113.4 10.157q5.079-3.754 12.807-8.832c5.741-3.827 9.863-6.772 12.66-8.538a14.812 14.812 0 002.5-2.208c.147-.147.147-.442.294-.589a12.384 12.384 0 011.764-1.912z"
            className="cls-7"
            data-name="Path 3485"
            transform="translate(77.941 20.536)"
          ></path>
          <path
            d="M298.047 52.883a2.251 2.251 0 012.65-.589 5.665 5.665 0 012.355 1.914 26.955 26.955 0 01.147 12.807 18.489 18.489 0 01-6.919 10.6 58.1 58.1 0 01-6.477 3.68 44.652 44.652 0 00-6.183 3.533c-2.061.883-4.269.442-6.477-1.325-2.355-1.914-3.386-3.68-3.239-5.447l.294-2.208a114.185 114.185 0 011.619-12.218A48.99 48.99 0 01279.94 52a4.034 4.034 0 012.8 2.5 9.98 9.98 0 01-.442 3.827 19.891 19.891 0 00-.883 3.975 4.072 4.072 0 001.619 3.533 27.268 27.268 0 007.949-5.594 90.727 90.727 0 017.064-7.358z"
            className="cls-20"
            data-name="Path 3486"
            transform="translate(104.845 13.124)"
          ></path>
          <path
            d="M221.647 225.627c2.8-7.213 5.594-14.721 8.244-22.817 6.772-19.431 10.01-37.1 10.01-52.848a53.272 53.272 0 00-1.767-12.807 39.892 39.892 0 01-7.508 1.472 31.711 31.711 0 01-11.777-.883 30.693 30.693 0 01-10.746-4.711 5.466 5.466 0 01-2.8-3.827 29.753 29.753 0 01-.294-5.005 68.265 68.265 0 00-7.066 21.051 86.81 86.81 0 00-.736 22.376c-.442 6.477-.442 16.193-.294 29.147-.147 11.629-1.178 21.2-3.091 28.853-2.8 7.949-4.269 17.371-4.122 28.264 0 3.827.147 8.685.589 14.426.147 3.239.589 7.949 1.03 14.279a78.774 78.774 0 01-.589 10.157 47.728 47.728 0 00-.147 10.157 1.954 1.954 0 001.178 1.619 13.726 13.726 0 002.061.736 31.911 31.911 0 008.244 1.03 10.3 10.3 0 007.36-3.091c1.472-1.325 2.061-3.533 1.914-6.624a136.834 136.834 0 001.03-15.31c0-6.919.147-12.071.147-15.457a107.9 107.9 0 011.325-15.31 61.507 61.507 0 014.269-14.721 34.712 34.712 0 001.472-5.3 20.049 20.049 0 011.914-5.152.456.456 0 01.15.296z"
            className="cls-9"
            data-name="Path 3487"
            transform="translate(65.097 47.208)"
          ></path>
          <path
            d="M227.987 139.562a55.26 55.26 0 011.767 12.807c0 15.751-3.386 33.416-10.01 52.848-2.8 8.1-5.594 15.6-8.244 22.817.147 2.208.442 5.3.736 8.98.147 1.914.294 3.827.589 6.036a88.062 88.062 0 010 15.31 54.293 54.293 0 00-.294 6.036 9.442 9.442 0 001.914 5.594c3.68 3.386 8.391 5.005 13.985 4.858 5.741-.147 10.3-2.061 13.4-6.036.442-1.619.147-3.975-.442-7.213a28.7 28.7 0 01-.883-7.508c.442-4.122.442-10.746.294-19.873a129.741 129.741 0 01.589-19.873 45.351 45.351 0 004.858-15.6c2.061-8.685 3.239-13.985 3.68-15.751a84.344 84.344 0 001.767-15.9 127 127 0 00-6.477-33.416 9.09 9.09 0 01-.736-3.68c0-.442.147-1.767.294-3.827.294-3.239-.294-5.594-1.619-7.066 0 .294-.294 2.355-.589 6.036a25.844 25.844 0 01-1.178 5.888 28.431 28.431 0 01-8.538 6.624.514.514 0 00-.294.147 12.736 12.736 0 01-2.5 1.03l-1.178.442c-.442.147-.736.147-.883.294z"
            className="cls-8"
            data-name="Path 3488"
            transform="translate(75.392 44.801)"
          ></path>
          <g data-name="Group 3222" transform="translate(246.409 81.804)">
            <path
              d="M225.942 63.9a40.055 40.055 0 0110.452 23.848 9.346 9.346 0 01-.589 3.975 2.584 2.584 0 01-.589.736 9.006 9.006 0 00-.736-1.766 39.422 39.422 0 00-1.914-3.386 5.763 5.763 0 00-1.178-1.472l-.736-.736c-.294-.147-.589-.147-.736-.294a3.5 3.5 0 00-2.65 2.5c-.147.294-.147.736-.294 1.03a2.505 2.505 0 00-.147 1.03c-.147 2.208-.294 4.416-.294 6.624a6.266 6.266 0 01-6.183-1.325 109.992 109.992 0 00-12.513-9.274c-3.827-2.355-7.655-4.858-11.335-7.066a26.016 26.016 0 015.3-8.833l.147.147c2.061-2.355 5.888-3.975 11.335-4.858a31.318 31.318 0 018.98-.736h2.061c.588.003 1.177-.144 1.619-.144zm12.807.147a15.394 15.394 0 015.594 2.8c1.619 3.975 4.711 7.655 9.421 11.041a13.976 13.976 0 012.5 1.472 37.39 37.39 0 00-2.944 3.386c-2.208 2.65-3.68 4.416-4.122 5.447a85.773 85.773 0 00-6.183-16.046c-1.322-2.8-2.794-5.447-4.266-8.1z"
              className="cls-7"
              data-name="Path 3489"
              transform="translate(-178.099 -63.063)"
            ></path>
            <path
              d="M202.254 78.3c3.827 2.355 7.655 4.711 11.335 7.066a109.994 109.994 0 0112.511 9.275 5.947 5.947 0 006.183 1.325c.147-2.208.147-4.416.294-6.624 0-.294.147-.736.147-1.03a2.73 2.73 0 01.294-1.03 3.52 3.52 0 012.65-2.5c.294.147.589.147.736.294.294.147.442.442.736.736a10.311 10.311 0 011.178 1.472 25.169 25.169 0 011.914 3.386c.294.589.442 1.178.736 1.766a2.584 2.584 0 00.589-.736 7.525 7.525 0 00.589-3.975 39.079 39.079 0 00-3.239-12.807 36.71 36.71 0 00-7.207-11.044c.883-.147 1.914-.147 2.8-.294a28.665 28.665 0 0110.01.442c1.472 2.65 2.8 5.3 4.122 7.949a85.773 85.773 0 016.183 16.046c.294 2.8.442 5.594.736 8.391.294 3.386.736 6.772 1.325 10.01a11.967 11.967 0 01.147 3.239c-.147 4.563-.589 9.127-1.178 13.838a83.1 83.1 0 00-.442 17.076 65.282 65.282 0 01.294 7.655 40.675 40.675 0 01-1.472 11.041 5.875 5.875 0 01-1.619 2.8 34.89 34.89 0 01-27.675 8.98 29.655 29.655 0 01-17.959-7.655 2.035 2.035 0 01-.736-1.325c-.589-5.005-1.472-10.01-2.5-14.868a133.585 133.585 0 00-3.827-13.1 38.409 38.409 0 00-12.66-17.371h-.589c-.736-2.8-1.472-5.3-1.914-7.655a18.467 18.467 0 01-.736-4.416v-5.3c0-2.8.883-6.477 3.091-11.041l2.208-.883a26.5 26.5 0 0012.945-9.133z"
              className="cls-21"
              data-name="Path 3490"
              transform="translate(-184 -63.331)"
            ></path>
          </g>
          <path
            d="M217.916 84.347c-.883 2.65-.147 4.563 2.208 5.888 1.03 2.944 2.65 7.508 4.711 13.543 1.619 5.3 2.65 10.01 2.65 14.132a98.643 98.643 0 01.294 15.751 80.8 80.8 0 00.589 15.751 4.6 4.6 0 001.325 1.619 19.263 19.263 0 001.914 1.03q4.637-5.3 3.533-10.157a106.124 106.124 0 01-1.325-12.365c-.147-5.594-.442-9.863-.589-12.66a81.947 81.947 0 00-1.619-12.366 40.061 40.061 0 00-4.707-11.481c-1.767-1.472-2.8-3.68-3.239-6.624a48.2 48.2 0 00-1.03-7.508 9.452 9.452 0 00-4.715 5.447z"
            className="cls-18"
            data-name="Path 3491"
            transform="translate(78.25 25.823)"
          ></path>
          <path
            d="M258.549 238.712a27.979 27.979 0 008.391 1.472 18.022 18.022 0 008.391-1.914c.442-3.091-.147-5.152-1.619-6.477q-3.533-3.533-11.041-6.183c-1.325-.442-3.386-1.178-6.036-2.061a45.788 45.788 0 01-5.888-2.65c-3.239 3.827-7.655 5.888-13.4 6.036-5.594.147-10.3-1.472-13.985-4.858a7.981 7.981 0 00-.883 3.68 5.99 5.99 0 001.178 3.533c1.619 2.355 4.416 3.827 8.244 4.416 1.03.147 2.8.294 5.005.442s3.827.147 4.858.294a35.749 35.749 0 018.391 1.767c3.684 1.031 6.481 1.914 8.394 2.503zm-57.706 22.081a31.911 31.911 0 01-8.244-1.03c.442 2.5 1.178 6.183 2.061 11.041a84.683 84.683 0 002.65 10.893 13.606 13.606 0 007.949 6.477 20.852 20.852 0 0010.452.442 3.556 3.556 0 002.65-1.914 4.953 4.953 0 00.589-3.239c-.294-3.827-1.619-8.1-4.269-13.1-1.472-2.8-2.65-4.858-3.386-6.183a56.546 56.546 0 01-2.944-6.33 11.529 11.529 0 01-7.507 2.943z"
            className="cls-22"
            data-name="Path 3492"
            transform="translate(66.469 92.859)"
          ></path>
          <path
            d="M252.67 237.052a74.62 74.62 0 006.477 2.061 28 28 0 006.772.736 30.182 30.182 0 005.3-.294c2.061-.589 2.944-1.767 2.65-3.68a16.051 16.051 0 01-8.391 1.914 33.586 33.586 0 01-8.391-1.472 178.893 178.893 0 01-8.391-2.8 39.007 39.007 0 00-8.391-1.767 35.06 35.06 0 00-4.858-.294 34.713 34.713 0 01-5.005-.442c-3.827-.589-6.624-2.061-8.244-4.416a25.679 25.679 0 00-.736 3.827 3.62 3.62 0 001.472 3.239 19.564 19.564 0 007.508 2.5c3.533.294 6.183.589 7.949.736a9.148 9.148 0 01.442-1.472 2.846 2.846 0 01.736-1.325 22.049 22.049 0 016.624 1.03 27.623 27.623 0 016.477 1.919zm-56.97 41.954c.589 3.975 3.239 6.772 7.508 8.244a20.065 20.065 0 0011.777.442c1.472-.294 2.208-1.325 2.5-2.944a14.736 14.736 0 00-.294-4.122 5.824 5.824 0 01-.589 3.239 3.556 3.556 0 01-2.65 1.914 18.05 18.05 0 01-10.452-.442 13 13 0 01-7.8-6.331z"
            className="cls-23"
            data-name="Path 3493"
            transform="translate(67.933 95.55)"
          ></path>
          <path
            d="M213.146 51.563c-1.03 0-2.65 0-4.563-.147a15.519 15.519 0 01-1.472 4.416c-.442 1.03-1.178 2.355-2.208 4.269-1.767 3.386-1.914 6.477-.736 9.127l-1.766.589c.589 3.975 3.386 7.066 8.244 9.274a35.074 35.074 0 0013.1 2.8c1.914-.442 3.091-1.766 3.68-3.975a48.516 48.516 0 01.883-5.741c1.325-9.569.589-17.959-2.355-25.173-1.766 2.355-4.416 3.68-7.949 4.122a12.436 12.436 0 01-4.858.439z"
            className="cls-20"
            data-name="Path 3494"
            transform="translate(71.096 10.763)"
          ></path>
          <g data-name="Group 3223" transform="translate(261.092 45.251)">
            <path
              d="M215.357 51.322a11.351 11.351 0 00-2.944-1.03c-1.767-.294-5.005-.736-9.569-1.325-3.386-.736-5.888-1.325-7.8-1.767l-.442 1.325a21.036 21.036 0 00-.589 6.183 13.127 13.127 0 00.442 3.239 6.375 6.375 0 00.147.736 23.373 23.373 0 008.98 14.279 6.835 6.835 0 00-1.472-2.944 22.844 22.844 0 00-2.208-2.65c-1.619-2.061-1.914-4.122-.736-5.888 2.061-1.03 3.68-.294 5.005 2.208a28.562 28.562 0 002.8 6.183l1.619-.589c-1.325-2.65-1.03-5.741.736-9.127 1.03-1.914 1.766-3.386 2.208-4.269A19.19 19.19 0 00213 51.469h1.767a.885.885 0 01.59-.147z"
              className="cls-8"
              data-name="Path 3495"
              transform="translate(-193.974 -34.393)"
            ></path>
            <path
              d="M215.109 55.429a9.748 9.748 0 012.208.147 47.372 47.372 0 004.858-.147 11.281 11.281 0 007.8-4.122 7.4 7.4 0 00.883-3.533 4.59 4.59 0 00-1.325-3.091 21.212 21.212 0 00-7.655-4.563 24.156 24.156 0 00-8.978-1.62c-6.919.147-12.071 2.65-15.457 7.655a19.658 19.658 0 00-2.944 6.477l.442-1.325c1.914.442 4.563 1.03 7.8 1.767 4.563.589 7.8 1.03 9.569 1.325a12.349 12.349 0 012.799 1.03z"
              className="cls-8"
              data-name="Path 3496"
              transform="translate(-193.726 -38.5)"
            ></path>
          </g>
          <path
            d="M196.8 59.783c1.325 5.3 3.091 8.244 5.3 8.685q.221.883.442 2.208a6.333 6.333 0 003.533 7.066 57.64 57.64 0 008.391 2.65 28.219 28.219 0 016.477 3.091l6.036 3.827a28.8 28.8 0 00.147-5.152c0-1.03-.294-2.8-.442-5.152a32.4 32.4 0 01-13.1-2.944c-4.858-2.061-7.508-5.152-8.244-9.274a35.094 35.094 0 01-2.8-6.036c-1.325-2.5-2.8-3.239-4.858-2.208a3.907 3.907 0 00-.736 2.061 3.241 3.241 0 00-.146 1.178z"
            className="cls-24"
            data-name="Path 3497"
            transform="translate(68.452 15.056)"
          ></path>
          <path
            d="M207.167 80.347c5.005 0 7.655 3.386 8.1 10.01s-1.914 12.513-7.066 17.665c-3.239 2.8-5.594 4.858-7.066 6.036-2.65 2.061-5.152 3.975-7.508 5.447-1.325.736-3.533 1.914-6.772 3.68a20.891 20.891 0 00-2.65 1.619 31.652 31.652 0 01-15.9 4.563 9.284 9.284 0 01-7.066-2.65 28.338 28.338 0 01-2.8-3.386l-1.766-2.208c-.442-.589-.736-1.325-1.178-2.061a22.751 22.751 0 01-2.355-9.274c-.147-.442-.147-.736-.294-1.178-.147-.147-.294-.442-.589-.736l-.442-4.269c-.589-3.68-.442-6.183.294-7.508.736-1.472 2.208-2.208 4.416-2.355a6.286 6.286 0 015.005 1.619 10.445 10.445 0 011.619 2.5 10.21 10.21 0 007.951 6.039 9.377 9.377 0 001.03.294c4.858-.442 9.716-2.5 14.574-6.33.442-.442 2.208-2.5 5.3-6.33a20.712 20.712 0 016.036-5.594 36.74 36.74 0 015.594-3.975c.294-.294 1.472-.736 3.239-1.766z"
            className="cls-7"
            data-name="Path 3498"
            transform="translate(47.045 26.437)"
          ></path>
          <path
            d="M142.981 69.1c-1.03 2.208-.294 4.711 2.061 7.213l3.091 3.091a15.649 15.649 0 012.355 3.239 38.289 38.289 0 00-6.919 6.183 11.222 11.222 0 00-2.65 8.391c3.975 2.65 6.33 4.269 7.066 4.711a45.359 45.359 0 007.508 3.827 14.4 14.4 0 007.508-1.03c2.8-1.178 3.68-3.091 2.65-5.594a41.162 41.162 0 00-8.538-17.223A60.3 60.3 0 00142.981 69.1z"
            className="cls-20"
            data-name="Path 3499"
            transform="translate(42.043 21.197)"
          ></path>
        </g>
      </svg>
    </IconStyled>
  )
}

export default ExpertiseIconSvg
