import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/allSite/seo/seo"

import Intro from "../components/Home/Intro"
import EssentialMods from "../components/Home/EssentialMods"
import Integration from "../components/Home/Integration"
import Company from "../components/Home/Company"
import Expertise from "../components/Home/Expertise"
import AreasExpert from "../components/Home/AreasExpert"
import Testimonials from "../components/Home/Testimonials"
import ContactForm from "../components/Home/ContactForm"

const IndexPage = props => {
  const {
    topSection,
    essMods,
    integrations,
    company,
    expertise,
    areasExpert,
    testimonials,
    seoInfo,
  } = props.data
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <Intro data={topSection} />
      <EssentialMods data={essMods} />
      <Integration data={integrations} />
      <Company data={company} />
      <Expertise data={expertise} />
      <AreasExpert data={areasExpert} />
      <Testimonials data={testimonials} />
      <ContactForm />
    </Layout>
  )
}
export const homeQuery = graphql`
  {
    seoInfo: wordpressPage(slug: { eq: "home" }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topSection: wordpressPage(slug: { eq: "home" }) {
      acf {
        _sol_tsec_title
        _sol_tsec_sub_title
        _sol_tsec_content
        _sol_tsec_button_req
        _sol_tsec_button_text
        _sol_tsec_button_link
        _sol_tsec_interior_page_slug
        _sol_tsec_exterior_link
        _sol_tsec_button2_req
        _sol_tsec_button2_text
        _sol_tsec_button2_link
        _sol_tsec_interior2_page_slug
        _sol_tsec_exterior2_link
        _sol_tsec_graphic_screen {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }

    essMods: wordpressPage(slug: { eq: "home" }) {
      acf {
        _sol_emod_main_title
        _sol_emod_sub_title
        _sol_emod_essential_modules {
          title
          description
          url
          icon {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    integrations: wordpressPage(slug: { eq: "home" }) {
      acf {
        _sol_inter_title
        _sol_inter_logos {
          link
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    company: wordpressPage(slug: { eq: "home" }) {
      acf {
        _sol_com_title
        _sol_com_content
      }
    }

    expertise: wordpressPage(slug: { eq: "home" }) {
      acf {
        _sol_expe_title
        _sol_expe_top_content
        _sol_expe_bottom_content
        _sol_expe_screen_main {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _sol_expe_screen_2nd {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _sol_expe_screen_3rd {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _sol_expe_screen_4th {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _sol_expe_screen_5th {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    areasExpert: wordpressPage(slug: { eq: "home" }) {
      acf {
        _sol_aoe_title
        _sol_aoe_expert_points {
          point
        }
      }
    }

    testimonials: wordpressPage(slug: { eq: "home" }) {
      acf {
        _sol_test_testimonials {
          big_title
          testimonial_content
          testimonial_author
        }
      }
    }
  }
`

export default IndexPage
