import React from "react"
import styled from "styled-components"

import WrapperDivSmall from "../StyledElements/WrapperDivSmall"
import HeadlineTwo from "../StyledElements/HeadlineTwo"
import { bodyCopyTwo } from "../../Utilities"
import ExpertiseLaptop from "./ExpertiseLaptop"

const ExpertiseStyled = styled.section`
  padding-top: 5rem;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    padding-top: 7rem;
    padding-bottom: 2.5rem;
  }

  @media (min-width: 1025px) {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }

  .expertiseWrapper {
    @media (min-width: 1025px) {
      max-width: 75rem;
    }
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      margin-top: 0;
    }
  }

  .content {
    ${bodyCopyTwo};
    width: 100%;
    text-align: center;

    p:last-of-type {
      margin: 0;
    }
  }
`

const Expertise = ({ data }) => {
  const title = data.acf._sol_expe_title
  const topContent = data.acf._sol_expe_top_content
  const screens = [
    data.acf._sol_expe_screen_main,
    data.acf._sol_expe_screen_2nd,
    data.acf._sol_expe_screen_3rd,
    data.acf._sol_expe_screen_4th,
    data.acf._sol_expe_screen_5th,
  ]
  const bottomContent = data.acf._sol_expe_bottom_content
  return (
    <ExpertiseStyled>
      <WrapperDivSmall modifier="expertiseWrapper">
        <div className="title">
          <HeadlineTwo type="h1">{title}</HeadlineTwo>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: topContent }}
        />
        <ExpertiseLaptop screens={screens} />
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: bottomContent }}
        />
      </WrapperDivSmall>
    </ExpertiseStyled>
  )
}

export default Expertise
