import React from "react"
import styled from "styled-components"

import HeadlineThree from "../StyledElements/HeadlineThree"
import BodyOne from "../StyledElements/BodyOne"

const StyledTest = styled.div`
  .author {
    font-style: italic;
  }
`

const TestSlide = ({ data }) => {
  const bigTitle = data.big_title
  const content = data.testimonial_content
  const author = data.testimonial_author
  return (
    <StyledTest>
      <div>
        <HeadlineThree type="h2">{bigTitle}</HeadlineThree>
      </div>
      <BodyOne>
        <p>{content}</p>
      </BodyOne>
      <BodyOne>
        <p className="author">{author}</p>
      </BodyOne>
    </StyledTest>
  )
}

export default TestSlide
