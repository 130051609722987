import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const IntegraLogoStyled = styled.div`
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 2rem;

  @media (min-width: 768px) {
    padding: 2.5rem;
  }

  @media (min-width: 1025px) {
    padding: 3rem;
  }
`

const IntegraLogo = ({ data }) => {
  const url = data.link
  const logoFluid = data.logo.localFile.childImageSharp.fluid
  const logoAlt = data.logo.alt_text
  return (
    <IntegraLogoStyled>
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <Img fluid={logoFluid} alt={logoAlt} />
      </a>
    </IntegraLogoStyled>
  )
}

export default IntegraLogo
