import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import HeadlineTwo from "../StyledElements/HeadlineTwo"
import WrapperDivSmall from "../StyledElements/WrapperDivSmall"
import TestSlide from "./TestSlide"
import { colors, fonts } from "../../Utilities"

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  draggable: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 15000,
  centerMode: true,
  centerPadding: "0px",
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
      },
    },
  ],
}

const TestimonialsSection = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: ${colors.veryLightBlue};

  .title {
    width: 100%;
    text-align: center;
  }

  .testSlider {
    @media (min-width: 768px) {
      max-width: 65rem;
    }
    @media (min-width: 1025px) {
      max-width: 75rem;
    }
  }

  .testimonialsSlider {
    position: relative;
    width: 100%;

    .slick-arrow {
      top: auto;
      bottom: -5rem;
      width: 3rem;
      height: 4rem;
      z-index: 5000;

      @media (min-width: 768px) {
        top: 50%;
        bottom: auto;
        width: 2.5rem;
        height: 2.5rem;
      }

      &::before {
        transition: all 0.3s ease-in-out;
        color: ${colors.colorPrimary};
        font-family: ${fonts.fontAwesome};
        font-size: 4rem;
      }

      @media (min-width: 768px) {
        &::before {
          font-size: 3rem;
        }
      }

      &:hover {
        &::before {
          color: ${colors.colorSecondary};
        }
      }
    }

    .slick-prev {
      left: 30%;

      @media (min-width: 768px) {
        left: -4.5rem;
      }

      @media (min-width: 1025px) {
        left: -5rem;
      }

      &::before {
        content: "\f053";
      }
    }

    .slick-next {
      right: 30%;

      @media (min-width: 768px) {
        right: -4.5rem;
      }

      @media (min-width: 1025px) {
        right: -5rem;
      }

      &::before {
        content: "\f054";
      }
    }
  }
`

const Testimonials = ({ data }) => {
  const tests = data.acf._sol_test_testimonials
  return (
    <TestimonialsSection>
      <WrapperDivSmall modifier="testSlider">
        <div className="title">
          <HeadlineTwo type="h1">What Our Customers Say</HeadlineTwo>
        </div>
        {tests.length > 0 && (
          <Slider className="testimonialsSlider" {...settings}>
            {tests.map((test, index) => {
              return <TestSlide key={index} data={test} />
            })}
          </Slider>
        )}
      </WrapperDivSmall>
    </TestimonialsSection>
  )
}

export default Testimonials
