import React from "react"
import styled from "styled-components"

import ExpertPoint from "./ExpertPoint"
import HeadlineTwo from "../StyledElements/HeadlineTwo"
// import AngledBackground from "../StyledElements/AngledBackground"
import WrapperDivSmall from "../StyledElements/WrapperDivSmall"
import ExpertiseIconSvg from "../../elements/ExpertiseIconSvg"

const AreasExpertSection = styled.section`
  position: relative;
  overflow: hidden;

  @media (min-width: 1025px) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .expertPointWrap {
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }

  .pointsContainer {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50%);
    }
  }

  .expertIconContainer {
    width: calc(100% - 15rem);
    margin: 2rem 7.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin: 2rem;
      padding: 2rem 5rem;
    }
  }
`

const AngledBackground = styled.div`
  position: absolute;
  bottom: -75rem;
  left: -180%;
  width: 175%;
  height: 100%;
  transform-origin: right bottom;
  transform: rotate(30deg);
  background-color: #e5f3f4;
  z-index: -1;

  @media (min-width: 500px) {
    bottom: -75rem;
    left: -100%;
    width: 150%;
  }

  @media (min-width: 768px) {
    width: 100%;
    top: -55rem;
    right: -50%;
    left: auto;
    bottom: auto;
    transform-origin: left top;
    transform: rotate(30deg);
  }

  @media (min-width: 1025px) {
    top: -50rem;
    right: -50%;
  }

  @media (min-width: 1200px) {
    top: -77.5rem;
    right: -50%;
    height: 75rem;
  }

  @media (min-width: 1600px) {
    top: -77.5rem;
  }
`

const AreasExpert = ({ data }) => {
  const title = data.acf._sol_aoe_title
  const points = data.acf._sol_aoe_expert_points
  return (
    <AreasExpertSection>
      <WrapperDivSmall modifier="expertPointWrap">
        <div className="pointsContainer">
          <div>
            <HeadlineTwo type="h1">{title}</HeadlineTwo>
          </div>
          {points.length > 0 && (
            <ul>
              {points.map((point, index) => {
                return <ExpertPoint key={index} data={point} />
              })}
            </ul>
          )}
        </div>
        <div className="expertIconContainer">
          <ExpertiseIconSvg />
        </div>
      </WrapperDivSmall>
      <AngledBackground top right />
    </AreasExpertSection>
  )
}

export default AreasExpert
